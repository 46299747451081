import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

function PortalFootter() {
  const history = useHistory();
  return (
    <div className="footer_wrap">
        <div className="copyright">
        	  <span className="copyright_txt01"><a href="https://www.htns.com/kor/" className="copyright_txt01" target="_blank">회사소개</a></span>
            <span className="copyright_div">|</span>
            <span onClick={() => {history.push('/app/FUL/TermsOfservice');}}  className="copyright_txt01">이용약관</span>
            <span className="copyright_div">|</span>
            <span onClick={() => {history.push('/app/FUL/PrivacyPolicy');}} className="copyright_txt01">개인정보처리방침</span>
            <span className="copyright_div">|</span>
            {/* <span onClick={() => history.push('/app/board/20210714090736000056')} className="copyright_txt01">고객센터</span>
            <span className="copyright_div">|</span>
            <span className="copyright_txt02">운영유지관리</span> */}
            <b>ⓒ Hanaro TNS.</b>
            <img src={require("../../../../images/consorlogos.png").default} className="consorlogos" />
        </div>
    </div>
  );
}
export default PortalFootter;