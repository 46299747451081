import { React, useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import PortalMain from "./views/PortalMain/PortalMain";
import home_url from "../js/globalProperties";
// import PortalBoard from "./views/PortalBoard/PortalBoard";
import PortalBoardRegister from "./views/PortalBoard/PortalBoardRegister";
import PortalBoardUpdate from "./views/PortalBoard/PortalBoardUpdate";
import PortalBoard2 from "./views/PortalBoard/PortalBoard2";
import PortalBoardGallery from "./views/PortalBoard/PortalBoardGallery";
import PortalBoardGallery2 from "./views/PortalBoard/PortalBoardGallery2";
import PortalHead from "./views/PortalMain/Sections/PortalHead";
import PortalLeftBanner from "./views/PortalMain/Sections/PortalLeftBanner";
import PortalRight from "./views/PortalMain/Sections/PortalRight";
import PortalFootter from "./views/PortalMain/Sections/PortalFootter";
import PortalFindSession from "./views/PortalCustomerService/PortalFindSession";
import Menu from "./views/PortalBoard/Menu";
import Dashboard from "./views/PortalBoard/Dashboard";

import CommonBoardMain from "./views/CommonBoard/BoardMain";
import CommonBoardRegister from "./views/CommonBoard/BoardRegister";
import CommonBoardDetail from "./views/CommonBoard/BoardDetail";
import CommonBoardUpdate from "./views/CommonBoard/BoardUpdate";
// import CommonBoardGellery   from "./views/CommonBoard/BoardGallery";
import CommonBoardGellery from "./views/CommonBoard/BoardList/CommonGallery";
import CommonBoardGallery2 from "./views/CommonBoard/BoardList/CommonGallery2";
// import Crosseditor  from "./views/index";
// 하나로TNS 기능
import TotalBoard from "./views/PortalBoard/TotalBoard";

import PLT000101_01 from "./views/PLT/PLT000101_01";
import PLT000101_02 from "./views/PLT/PLT000101_02";
import PLT000101_03 from "./views/PLT/PLT000101_03";
import PLT000101_04 from "./views/PLT/PLT000101_04";
import PLT000101_05 from "./views/PLT/PLT000101_05"; // 디지털트윈


import PLT000101_07 from "./views/PLT/PLT000101_07"; // 마이페이지
import PLT000101_09 from "./views/PLT/PLT000101_09";
import PLT000101_10 from "./views/PLT/PLT000101_10";
import PLT000101_11 from "./views/PLT/PLT000101_11"; // iot
import PLT000101_12 from "./views/PLT/PLT000101_12"; // 본인확인
import PLT000101_13 from "./views/PLT/PLT000101_13"; // 비밀번호 변경

// 포털페이지
import PLT010101 from "./views/PLT/PLT010101";
import PLT010201 from "./views/PLT/PLT010201";
import PLT010301 from "./views/PLT/PLT010301";
import PLT010401 from "./views/PLT/PLT010401";
import PLT010501 from "./views/PLT/PLT010501";
import PLT010601 from "./views/PLT/PLT010601";
import PLT010701 from "./views/PLT/PLT010701";
import PLT010801 from "./views/PLT/PLT010801";

import PLT020101 from "./views/PLT/PLT020101";
import PLT030101 from "./views/PLT/PLT030101";
import PLT030301 from "./views/PLT/PLT030301";
import PLT030401 from "./views/PLT/PLT030401";
import PLT030501 from "./views/PLT/PLT030501";

//하나로TNS 대시보드
import PLT020201 from "./views/CommonDashBoard/PLT020201";
import PLT020301 from "./views/CommonDashBoard/PLT020301";
import PLT020401 from "./views/CommonDashBoard/PLT020401";
import PLT020501 from "./views/CommonDashBoard/PLT020501";

//로그인 접근제한 추가
import PrivateRoute from './views/PortalMain/Route/PrivateRoute';

//개인정보수집 및 이용동의
import PoliciesPopup from './views/PortalMain/Sections/Policies_Popup';
import Terms from "./views/PortalMain/Sections/Terms";

import PortalTerms from "./views/PortalCustomerService/PortalTerms"; // 회원가입(약관페이지)
import PortalRegister from "./views/PortalCustomerService/PortalRegister"; // 회원가입(가입페이지))

//쪽지기능
import NoteMain from "./views/PortalNote/NoteMain";

//중고거래
import TradeMain from "./views/PortalTrade/TradeMain";
import TradeRegister from "./views/PortalTrade/TradeRegister";
import TradeUpdate from "./views/PortalTrade/TradeUpdate";
import TradeDetail from "./views/PortalTrade/TradeDetail";
import $ from 'jquery'
window.$ = $;

const bannerClose = () => {
  $('.bannerPop, .bannerPop_Img, .bannerPop_ImgMain, .bannerPop_ImgWrap, .bannerPop_logo, .coffeeBanner_Img, .coffee_banner_button1, .coffee_banner_button2').css('display','none');
  $('body').css('overflow','auto'); 
}

const App = () => {
  let path = useLocation();
  const [pathname, setPathname] = useState(path.pathname);

  useEffect(() => {
    setPathname(path.pathname);
  }, [path])

  console.log(path);

//   if(process.env.REACT_APP_SSO_MODE === 'pro'){
//     if(localStorage.getItem('SSO-INDEX') != null){
//       console.log(localStorage.getItem('SSO-INDEX'));
//       // window.location = "/";
//       localStorage.removeItem('SSO-INDEX');
    
//     }else{ // 주소가 /exsignon이 아니고 세션 정보가 없을 때만 sso_index 페이지로?
//       //메인 페이지 접속 &
//       if(pathname === '/' && localStorage.getItem('LoginType') === null){
//         localStorage.setItem('SSO-INDEX', true);
//         window.location =  "/exsignon/sso/sso_index.jsp?RelayState=/";
//         return false;
//       }
//     }
//   }

// if(pathname === '/exsignon/sso/slores.jsp'){
//   window.location = '/';
//   return false;
// }

const showPortalRight = !pathname.startsWith("/app/FUL/");

  if (pathname.startsWith("/app/POPUP")) {
    return (
      <div>
        <Route exact path="/app/POPUP/PLT000101_01" component={PLT000101_01} />
        <Route exact path="/app/POPUP/PLT000101_02" component={PLT000101_02} />
        <Route exact path="/app/POPUP/PLT000101_03" component={PLT000101_03} />
        <Route exact path="/app/POPUP/PLT000101_04" component={PLT000101_04} />
        <Route exact path="/app/POPUP/PLT000101_09" component={PLT000101_09} />
        <Route exact path="/app/POPUP/PLT000101_10" component={PLT000101_10} />
        <Route exact path="/app/POPUP/PLT000101_10" component={PLT000101_10} />
        <Route exact path="/app/POPUP/PoliciesPopup" component={PoliciesPopup} />
      </div>
    );
  }

  return (
    <div>


      <div className="bannerPop"></div>
      <div className="bannerPop_ImgWrap">
        <div className="bannerPop_Img">
          <img src={require("../images/main.jpg").default} className="bannerPop_ImgMain" />
          <img src={require("../images/joinCoffeeCouponDetail.png").default} className="coffeeBanner_Img" />
          <img src={require("../images/purpose.jpg").default} className="bannerPop_Img01" />
          <img src={require("../images/vision.jpg").default} className="bannerPop_Img02" />
          <img src={require("../images/service.jpg").default} className="bannerPop_Img03" />
          <img src={require("../images/direct.jpg").default} className="bannerPop_Img04" />
          <img src={require("../images/consor.jpg").default} className="bannerPop_Img05" />
          <div className="bannerPop_menuWrap">
            <span className="bannerPop_menu01">목표구성도</span>
            <span>·</span>
            <span className="bannerPop_menu02">비전</span>
            <span>·</span>
            <span className="bannerPop_menu03">서비스</span>
            <span>·</span>
            <span className="bannerPop_menu04">추진방향</span>
            <span>·</span>
            <span className="bannerPop_menu05">컨소시엄 소개</span>
          </div>
          <div className="bannerPop_logo">
            스마트 물류플랫폼
          </div>
          <div className="bannerPop_Xbtn" onClick={() => bannerClose()}>X</div>
          <button className="coffee_banner_button1" style={{
            'backgroundColor': '#006942',
            'border': '1px solid #006942',
            'borderRadius': '4px',
            'boxShadow': 'rgba(0, 0, 0, .1) 0 2px 4px 0',
            'boxSizing': 'border-box',
            'color': '#fff',
            'cursor': 'pointer',
            'fontFamily': '"Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif',
            'fontSize': '16px',
            'fontWeight': '400',
            'outline': 'none',
            'outline': '0',
            'padding': '10px 25px',
            'textAlign': 'center',
            'userSelect': 'none',
            'WebkitUserSelect': 'none',
            'touchAction': 'manipulation',
            'position': 'absolute',
            'left': '350px',
            'bottom': '50px'
          }}
            onClick={() => {
              window.open("https://myslp.kr/resources/info/스마트_물류플랫폼 참여_의향서.pdf");
            }}
          >스마트물류플랫폼 참여 의향서 다운로드</button>
          <button className="coffee_banner_button2" style={{
            'backgroundColor': '#006942',
            'border': '1px solid #006942',
            'borderRadius': '4px',
            'boxShadow': 'rgba(0, 0, 0, .1) 0 2px 4px 0',
            'boxSizing': 'border-box',
            'color': '#fff',
            'cursor': 'pointer',
            'fontFamily': '"Akzidenz Grotesk BQ Medium", -apple-system, BlinkMacSystemFont, sans-serif',
            'fontSize': '16px',
            'fontWeight': '400',
            'outline': 'none',
            'outline': '0',
            'padding': '10px 25px',
            'textAlign': 'center',
            'userSelect': 'none',
            'WebkitUserSelect': 'none',
            'touchAction': 'manipulation',
            'position': 'absolute',
            'left': '750px',
            'bottom': '50px'
          }}
            onClick={() => {
              window.open('https://myslp.kr/app/FUL/Terms')
            }}
          >회원가입</button>
        </div>
      </div>

      <PortalHead />
      <div className={window.location.href.indexOf('PLT000101_11') > 0 ? "contents_wrap2" : "contents_wrap"}>
        {/* 풀페이지용 */}
        <Switch>
          <Route exact path="/app/FUL/Terms" component={PortalTerms} /> {/* 약관동의 */}
          <Route exact path="/app/FUL/Register" component={PortalRegister} /> {/* 회원가입 */}
          <Route exact path="/app/FUL/MyPage" component={PLT000101_07} /> {/* 마이페이지 */}
          <PrivateRoute exact path="/app/FUL/PLT000101_05" component={PLT000101_05} /> {/* 디지털트윈 */}
          <PrivateRoute exact path="/app/FUL/PLT000101_11" component={PLT000101_11} /> {/* iot관제 */}
          <PrivateRoute exact path="/app/FUL/PLT000101_12" component={PLT000101_12} /> {/* 본인인증 */}
          <PrivateRoute exact path="/app/FUL/PLT000101_13" component={PLT000101_13} /> {/* 비밀번호 변경 */}
          <PrivateRoute exact path="/app/FUL/Note" component={NoteMain} /> {/* 쪽지 */}
          <Route exact path="/app/FUL/TermsOfservice" component={Terms} /> {/* 약관 */}
          <Route exact path="/app/FUL/PrivacyPolicy" component={Terms} /> {/* 약관 */}
        </Switch>
        <div className="contents"  id="contents" style={{ display: !pathname.startsWith("/app/FUL/") ? '' : 'none' }}>
          {/* 풀페이지가 아닌 경우에만 베너가 보여지도록 조정 */}
          {!pathname.startsWith("/app/FUL/") && <PortalLeftBanner />}
          {/* <PortalLeftBanner/> */}
          <Switch>
            <Route exact path="/" component={PortalMain} />
            <Route exact path="/index.html" component={PortalMain} />
            <Route exact path="/app/findsession" component={PortalFindSession} />
            <PrivateRoute exact path="/app/boardregister" component={PortalBoardRegister} />
            <PrivateRoute exact path="/app/boardupdate/:bbs_id" component={PortalBoardUpdate} />
            <PrivateRoute exact path="/app/board2" component={PortalBoard2} />
            <PrivateRoute exact path="/app/boardgallery" component={PortalBoardGallery} />
            <PrivateRoute exact path="/app/boardgallery2" component={PortalBoardGallery2} />
            <PrivateRoute exact path="/app/Menu" component={Menu} />
            <PrivateRoute exact path="/app/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/app/totalboard" component={TotalBoard} />
            <PrivateRoute exact path="/app/board/register" component={CommonBoardRegister} />
            <PrivateRoute exact path="/app/board/:bbs_id/:ntt_id" component={CommonBoardDetail} />
            <PrivateRoute exact path="/app/board/update" component={CommonBoardUpdate} />
            <PrivateRoute exact path="/app/board/:bbs_id" component={CommonBoardMain} />
            <PrivateRoute exact path="/app/commonboardgellery" component={CommonBoardGellery} />
            <PrivateRoute exact path="/app/commonboardgellery2" component={CommonBoardGallery2} />

            <PrivateRoute exact path="/app/trade" component={TradeMain} />
            <PrivateRoute exact path="/app/trade/register" component={TradeRegister} />
            <PrivateRoute exact path="/app/trade/update/:deal_no" component={TradeUpdate} />
            <PrivateRoute exact path="/app/trade/:deal_type/:company_code/:deal_no" component={TradeDetail} />

            <Route exact path="/app/PLT010101" component={PLT010101} />
            <Route exact path="/app/PLT010201" component={PLT010201} />
            <Route exact path="/app/PLT010301" component={PLT010301} />
            <Route exact path="/app/PLT010401" component={PLT010401} />
            <Route exact path="/app/PLT010501" component={PLT010501} />
            <Route exact path="/app/PLT010601" component={PLT010601} />
            <Route exact path="/app/PLT010701" component={PLT010701} />
            <Route exact path="/app/PLT010801" component={PLT010801} />
            <Route exact path="/app/PLT030101" component={PLT030101} />
            <Route exact path="/app/PLT030301" component={PLT030301} />
            <Route exact path="/app/PLT030401" component={PLT030401} />
            <Route exact path="/app/PLT030501" component={PLT030501} />
            {/* 통합대시보드 */}
            <PrivateRoute exact path="/app/PLT020101" component={PLT020101} />
            {/* 물류관리의 시각화기능내 */}
            <PrivateRoute exact path="/app/PLT020201" component={PLT020201} />
            <PrivateRoute exact path="/app/PLT020301" component={PLT020301} />
            <PrivateRoute exact path="/app/PLT020401" component={PLT020401} />
            <PrivateRoute exact path="/app/PLT020501" component={PLT020501} />
          </Switch>
        </div>
        {/* 풀페이지가 아닌 경우에만 오른쪽 패널이 보여지도록 조정 */}
        {showPortalRight  && <PortalRight />}
        {/* <PortalRight/> */}
      </div>
      <PortalFootter />
    </div>
  );
}

export default App;