import React from "react";
import { Link } from "react-router-dom";
import $ from 'jquery'
window.$ = $;

const bannerOpen = () => {
  $('.bannerPop_logo, .bannerPop_Img01, .bannerPop_Img02, .bannerPop_Img03, .bannerPop_Img04, .bannerPop_Img05, .coffeeBanner_Img, .coffee_banner_button1, .coffee_banner_button2').css('display','none');
    $('.bannerPop, .bannerPop_Img, .bannerPop_ImgMain, .bannerPop_ImgWrap, .bannerPop_menuWrap').css('display','block'); 
    $('body').css('overflow','hidden'); 
    var popBgPosition = document.documentElement.scrollTop;
    var popBgHeight = window.scrollHeight;
    $('.bannerPop_ImgWrap, .bannerPop').css('top',popBgPosition); 
    $('.bannerPop').css('height',popBgHeight); 
}

function PortalLeftBanner() {
  return (
    <>
      {/* <div className="main_banner_wrap slides" >
       // onClick={() => bannerOpen()}> 
        <img src={require("../../../../images/main_banner.png").default} className="" />
      </div> */}
    </>
  );
}
export default PortalLeftBanner;