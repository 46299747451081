import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import reactDom from "react-dom";
import PortalDetail from "./Sections/PortalDetail";
// import { Button, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { boardActions } from "../../../slice/boardSlice";
import { articleActions } from "../../../slice/articleSlice";
import { createSelector } from "@reduxjs/toolkit";
import PortalList from "./PortalList";
import { Grid } from '@material-ui/core';
import JobList from "./JobList";
import $ from 'jquery'
import PortalMainDash from './PortalMainDash';
// import PortalMainDashOnSession from './PortalMainDashOnSession';
import moment from 'moment';
import cookies from "react-cookies"
import { home_url } from "../../../js/globalProperties";
import PropTypes from "prop-types";
import TradeList from "../PortalTrade/TradeList";
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Button
} from "@material-ui/core";
window.$ = $;

const { Title } = Typography;

function PortalMain() {

  const [loginStatus, setLoginStatus] = useState(false);
  // const [tradeCategory, setTradeCategory] = useState("");
  // const [tradeCategoryArr, setTradeCategoryArr] = useState([]);
  // const [companyCategory, setCompanyCategory] = useState("");
  // const [companyCategoryArr, setCompanyCategoryArr] = useState([]);
  // const [productCategory, setProductCategory] = useState("");
  // const [productCategoryArr, setProductCategoryArr] = useState([]);
  // const [tradeItems, setTradeItems] = useState('');
  // const [wishList, setWishList] = useState([]);
  const history = useHistory();

  const goBoard = (bbs_data) => {
    history.push({ pathname: '/app/board/' + bbs_data['BBS_ID'], state: bbs_data });
  };

  // const getCATS = (bbs_id, setState) => {
  //   let requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
  //     body: JSON.stringify({ 'BBS_ID': bbs_id, 'OPEN_API_YN': 'Y' })
  //   };

  //   fetch(home_url + '/api/BRD140010SVC/get', requestOptions)
  //     .then(function (response) { return response.json(); })
  //     .then(function (data) {
  //       if (data.DATA) setState(data.DATA);
  //     })
  // }

  // const getTradeCATS = (setState) => {
  //   let requestOptions = {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json', "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
  //     body: JSON.stringify({ 'OPEN_API_YN': 'Y' })
  //   };
  //   fetch(home_url + '/api/MBP040010SVC/getCode', requestOptions)
  //     .then(function (response) { return response.json(); })
  //     .then(function (data) {
  //       if (data.MBP040010G1) setState(data.MBP040010G1);
  //     })
  // }

  // const getTradeList = (category) => {
  //   try {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         DEAL_TYPE: "SEL",
  //         CATEGORY: category,
  //         KEYWORD_SEARCH: null,
  //         S_FUNC_CODE: "DL",
  //         NO_FINISH: "F",
  //         OPEN_API_YN: "Y"
  //       }),
  //     };
  //     fetch(home_url + '/api/MBP080010SVC/getList', requestOptions)
  //       .then(function (response) { return response.json(); })
  //       .then(function (data) {
  //         let arr = [];
  //         if(data.TYPE == 1){
  //       	  if (data.MBP080010G1.length > 0) {
	//               for (let i = 0; i < 4; i++) {
	//                 if (data.MBP080010G1[i] !== undefined)
	//                   arr.push(data.MBP080010G1[i]);
	//               }
	//           }
	//           setTradeItems(arr);
  //         }
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // const getWishList = () => {
  //   try {
  //     const requestOptions = {
  //       method: 'POST',
  //       headers: { "Content-Type": "application/json", "AJAX": true, "X-CSRF-TOKEN": localStorage.getItem("X-CSRF-TOKEN") },
  //       body: JSON.stringify({
  //         "MBP100010F1": {
  //           "USER_ID": localStorage.getItem("USER_ID"),
  //           "S_FUNC_CODE": "DL"
  //         }
  //       }),
  //     };
  //     fetch(home_url + '/api/MBP100010SVC/getList', requestOptions)
  //       .then(function (response) { return response.json(); })
  //       .then(function (data) {
  //         if (data.MBP100010G1) {
  //           let wishList = [];
  //           for (let i in data.MBP100010G1) {
  //             wishList.push(data.MBP100010G1[i].DEAL_NO);
  //           }
  //           setWishList(wishList);
  //         }
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  //TabPanel----------------------------------------------------------------------------
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  //-----------------------------------------------------------------------------------
  // useEffect(() => {
  //   getTradeList(tradeCategory);
  //   // if (localStorage.getItem("USER_ID") !== null) getWishList();
  // }, [tradeCategory])

  useEffect(() => {
    // getTradeCATS(setTradeCategoryArr);//중고거래
    // getCATS('20210708100737000048', setCompanyCategoryArr);//입주기업
    // getCATS('20210715110704000074', setProductCategoryArr);//제품소개
    if (localStorage.getItem("LoginType") !== null) setLoginStatus(true);
  }, []);

  const logisticRef = useRef();
  // const tradeRef = useRef();
  // const companyRef = useRef();
  // const productRef = useRef();
  // const recuritRef = useRef();

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth"
    });
  }

  const items = [
    {
      type: 'video',
      src: 'https://myslp.kr/resources/info/smart.mp4?autoplay=1',
      label: '소개영상',
    },
    // {
    //   type: 'image',
    //   src: require("../../../images/main.png").default,
    //   label: '목표구성도',
    // },
    {
      type: 'image',
      src: require("../../../images/diagram.png").default,
      label: '목표구성도',
    },
    {
      type: 'image',
      src: require("../../../images/vision.png").default,
      label: '비전',
    },
    {
      type: 'image',
      src: require("../../../images/service.png").default,
      label: '서비스',
    },
    {
      type: 'image',
      src: require("../../../images/promotion.png").default,
      label: '추진방향',
    },
    {
      type: 'image',
      src: require("../../../images/consortium.png").default,
      label: '컨소시엄',
    },
  ];

 
    const [currentIndex, setCurrentIndex] = useState(0);
  
    // const handleClick = () => {
    //   setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    // };
    const handleClick = (index) => {
      if (index < items.length) {
        console.log(`Tab ${index} clicked`);
      setCurrentIndex(index);
      console.log(`Current index: ${index}`);
      // scrollToRef(logisticRef);
      }
    };
  return (

    <>
    <div className="logistics_contents_wrap" ref={logisticRef}>
      <div className="title_tab_wrap">
          <ul>
            {items.map((item, index) => (
              <li key={index} className={`title_tab_select ${currentIndex === index ? 'active' : ''}`}>
                <a onClick={() => handleClick(index)} style={{ cursor: "pointer" }}>
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {items[currentIndex] && ( // items[currentIndex]가 정의되어 있는지 확인
        items[currentIndex].type === 'video' ? (
          <video controls autoPlay muted width="792" height="605" src={items[currentIndex].src}></video>
        ) : (
          <img src={items[currentIndex].src} alt={items[currentIndex].label} width="800" height="auto" />
        )
      )}
    </div>
      {/* <div className="logistics_contents_wrap" ref={logisticRef}>
        <div className="title_tab_wrap">
          {/* <ul>
            <li className="title_tab_select"><a onClick={() => scrollToRef(logisticRef)} style={{ cursor: "pointer" }}>물류관리</a></li>
            <li className="title_tab_select"><a onClick={() => scrollToRef(logisticRef)} style={{ cursor: "pointer" }}>물류관리</a></li>
            <li className="title_tab_select"><a onClick={() => scrollToRef(logisticRef)} style={{ cursor: "pointer" }}>물류관리</a></li>
            <li className="title_tab_select"><a onClick={() => scrollToRef(logisticRef)} style={{ cursor: "pointer" }}>물류관리</a></li>
            <li className="title_tab_select"><a onClick={() => scrollToRef(logisticRef)} style={{ cursor: "pointer" }}>물류관리</a></li>

            {/* <li><a onClick={() => scrollToRef(tradeRef)} style={{ cursor: "pointer" }}>중고거래</a></li>
            <li><a onClick={() => scrollToRef(companyRef)} style={{ cursor: "pointer" }}>입주기업</a></li>
            <li><a onClick={() => scrollToRef(productRef)} style={{ cursor: "pointer" }}>제품소개</a></li>
            <li><a onClick={() => scrollToRef(recuritRef)} style={{ cursor: "pointer" }}>구인구직</a></li> 
          </ul> 
        </div>
        {/* {loginStatus ? <PortalMainDash /> : <PortalMainDash />} 
        <video controls autoPlay="autoplay" muted
              src="https://myslp.kr/resources/info/smart.mp4?autoplay=1" 
              width="800" 
              height="385" 
            ></video>
      </div> */}
      {/* <div className="logistics_contents_wrap" ref={tradeRef}>
        <div className="title_tab_wrap">
          <ul>
            <li><a onClick={() => scrollToRef(logisticRef)} style={{ cursor: "pointer" }}>물류관리</a></li>
            <li className="title_tab_select"><a onClick={() => scrollToRef(tradeRef)} style={{ cursor: "pointer" }}>중고거래</a></li>
            <li><a onClick={() => scrollToRef(companyRef)} style={{ cursor: "pointer" }}>입주기업</a></li>
            <li><a onClick={() => scrollToRef(productRef)} style={{ cursor: "pointer" }}>제품소개</a></li>
            <li><a onClick={() => scrollToRef(recuritRef)} style={{ cursor: "pointer" }}>구인구직</a></li>
          </ul>
        </div>
        <Typography component={"span"}>
          <Tabs className="tab_shape_control"
            onChange={(e, value) => setTradeCategory(value)}
            value={tradeCategory}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
            <Tab label='전체' {...a11yProps('')} value="" />
            {tradeCategoryArr.map((row) => (
              <Tab label={row.LOC_VALUE} {...a11yProps(row.ENG_VALUE)} value={row.ENG_VALUE} />
            ))}
            <span className="addView_Btn" onClick={() => history.push("/app/trade")}>+ 더보기</span>
          </Tabs>
          <div className="quickbbs_thumbnail quickbbs_thumbnail_01margin">
            <TradeList ITEMS={tradeItems} WISHLIST={wishList} LOCATION='MAIN' />
          </div>
        </Typography>
      </div> */}
      {/* <div className="logistics_contents_wrap" ref={companyRef}>
        <div className="title_tab_wrap">
          <ul>
            <li><a onClick={() => scrollToRef(logisticRef)} style={{ cursor: "pointer" }}>물류관리</a></li>
            <li><a onClick={() => scrollToRef(tradeRef)} style={{ cursor: "pointer" }}>중고거래</a></li>
            <li className="title_tab_select"><a onClick={() => scrollToRef(companyRef)} style={{ cursor: "pointer" }}>입주기업</a></li>
            <li><a onClick={() => scrollToRef(productRef)} style={{ cursor: "pointer" }}>제품소개</a></li>
            <li><a onClick={() => scrollToRef(recuritRef)} style={{ cursor: "pointer" }}>구인구직</a></li>
          </ul>
        </div>
        <Typography component={"span"}>
          <Tabs className="tab_shape_control"
            onChange={(e, value) => setCompanyCategory(value)}
            value={companyCategory}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
            <Tab label='전체' {...a11yProps('')} value="" />
            {companyCategoryArr.map((row) => (
              <Tab label={row.CAT_NAME} {...a11yProps(row.CAT_NAME)} value={row.CAT_NAME} />
            ))}
            <span className="addView_Btn" onClick={() => goBoard({ BBS_ID: "20210708100737000048" })}>+ 더보기</span>
          </Tabs>
          <div className="quickbbs_thumbnail quickbbs_thumbnail_01margin">
            <PortalList bbsId='20210708100737000048' catName={companyCategory} />
          </div>
        </Typography>
      </div> */}
      {/* <div className="logistics_contents_wrap" ref={productRef}>
        <div className="title_tab_wrap">
          <ul>
            <li><a onClick={() => scrollToRef(logisticRef)} style={{ cursor: "pointer" }}>물류관리</a></li>
            <li><a onClick={() => scrollToRef(tradeRef)} style={{ cursor: "pointer" }}>중고거래</a></li>
            <li><a onClick={() => scrollToRef(companyRef)} style={{ cursor: "pointer" }}>입주기업</a></li>
            <li className="title_tab_select"><a onClick={() => scrollToRef(productRef)} style={{ cursor: "pointer" }}>제품소개</a></li>
            <li><a onClick={() => scrollToRef(recuritRef)} style={{ cursor: "pointer" }}>구인구직</a></li>
          </ul>
        </div>
        <Typography component={"span"}>
          <Tabs className="tab_shape_control"
            onChange={(e, value) => setProductCategory(value)}
            value={productCategory}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example">
            <Tab label='전체' {...a11yProps('')} value="" />
            {productCategoryArr.map((row) => (
              <Tab label={row.CAT_NAME} {...a11yProps(row.CAT_NAME)} value={row.CAT_NAME} />
            ))}
            <span className="addView_Btn" onClick={() => goBoard({ BBS_ID: "20210715110704000074" })}>+ 더보기</span>
          </Tabs>
          <div className="quickbbs_thumbnail quickbbs_thumbnail_01margin">
            <PortalList bbsId='20210715110704000074' catName={productCategory} />
          </div>
        </Typography>
      </div> */}
      {/* <div className="logistics_contents_wrap" ref={recuritRef}>
        <div className="title_tab_wrap">
          <ul>
            <li><a onClick={() => scrollToRef(logisticRef)} style={{ cursor: "pointer" }}>물류관리</a></li>
            <li><a onClick={() => scrollToRef(tradeRef)} style={{ cursor: "pointer" }}>중고거래</a></li>
            <li><a onClick={() => scrollToRef(companyRef)} style={{ cursor: "pointer" }}>입주기업</a></li>
            <li><a onClick={() => scrollToRef(productRef)} style={{ cursor: "pointer" }}>제품소개</a></li>
            <li className="title_tab_select"><a onClick={() => scrollToRef(recuritRef)} style={{ cursor: "pointer" }}>구인구직</a></li>
          </ul>
        </div>
        <div className="quickbbs_wrap">
          <div className="quickbbs_dadanWrap01">
            <div className="quickbbs_tab_wrap">
              <span className="quickbbs_tab_select">
                구인
              </span>
              <span className="float_R" onClick={() => goBoard({ BBS_ID: "20210714090756000066" })}>+ 더보기</span>
            </div>
          </div>
          <div className="quickbbs_dadanWrap02">
            <div className="quickbbs_tab_wrap">
              <span className="quickbbs_tab_select">
                구직
              </span>
              <span className="float_R" onClick={() => goBoard({ BBS_ID: "20210820010819000108" })}>+ 더보기</span>
            </div>
          </div>
          <div className="quickbbs_dadanWrap01">
            <ul className="quickbbs_list">
              <JobList bbsId='20210714090756000066' />
            </ul>
          </div>
          <div className="quickbbs_dadanWrap02">
            <ul className="quickbbs_list">
              <JobList bbsId='20210811090849000107' />
            </ul>
          </div>
        </div>
      </div> */}
      <br />
      <br />
    </>
  );
}
export default PortalMain;