import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT010601(props) {

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          {/* <Typography className="listTable_tt">
          특허
          </Typography> */}
          {/* <Divider/> */}
          <div className="common_totalWrap" style={{position:"relative"}}>
         
      
          <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                     특허 등록 내용 (드론용 통합 제어 시스템)
              <span className="common_ttIcon3"></span>         
                     특허 등록 내용 (드론의 온도 제어 장치)
          </div>
          <div className="common_txt">
             <div className="common_txt2">
            - 특허 : 제 10-2321351호<br/>
            - 출원번호 : 제 10-2021-0068798호<br/>
            - 출원일 : 2021-05-28<br/>
            - 등록일 : 2021-10-28<br/>
            - 출원인 : 주식회사 하나로티앤에스<br/>
            </div>
            <div className="common_txt3"> 
            - 특허 : 제 10-2321352호 <br/>
            - 출원번호 : 제 10-2021-0068799호<br/>
            - 출원일 : 2021-05-28<br/>
            - 등록일 : 2021-10-28<br/>
            - 출원인 : 주식회사 하나로티앤에스<br/>
            </div>
          </div>
           <br/>

           <div className="common_img">
            <img src={require("../../../images/certificate_dron_img1.png").default} className="" style={{ width: '350px', height: 'auto' }}  />
            <img src={require("../../../images/certificate_dron_img2.png").default} className="" style={{ width: '350px', height: 'auto' }}/>
          </div>
          {/* <div className="common_img">
            <img src={require("../../../images/certificate_dron_img1.png").default} className="" style={{ width: '700px', height: 'auto' }}  />
          </div> */}

          {/* <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
               특허 등록 내용 (드론의 온도 제어 장치)

          </div>
          <div className="common_txt">
            - 특허 : 제 10-2321352호
            - 출원번호 : 제 10-2021-0068799호<br/>
            - 출원일 : 2021-05-28<br/>
            - 등록일 : 2021-10-28<br/>
            - 출원인 : 주식회사 하나로티앤에스<br/>
   
          </div> */}
          <br/>
          {/* <div className="common_img">
            <img src={require("../../../images/certificate_dron_img2.png").default} className="" style={{ width: '700px', height: 'auto' }}/>
          </div> */}
          <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
             요약
          </div>
          <div className="common_txt">
             드론의 온도 제어 장치가 제공된다. <br/>
             본 발명의 실시예에 따른 드론의 온도 제어 장치는 원격 조정기 또는 지상관제시스템(GCS)과 <br/>
             통신하는 통신부, 드론의 본체에 내장되는 제어부, 및 본체에 전원을 공급하는 배터리를 <br/>
             발열시키는 발열부 및 본체를 냉각시키는 냉각부를 포함하는 온도조절부를 포함한다. <br/>
             여기서, 제어부는 배터리의 온도 하락이 예상되면 발열부를 구동시키고, <br/>
             본체의 온도 상승이 예상되면 상기 냉각부를 구동시키도록 제어한다.<br/>
             <br/><br/><br/>
          </div>
          {/* <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            차량관제 내용
          </div>
          <div className="common_img">
            <div className="common_imgTT02">
                              차량관제 모니터링[웹뷰 -1]
            </div>
            <div className="common_txtAlign">
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>         
                                위치추적
              </div>
              <div className="common_txt">
                - 최종위치 추적 : 배송 차량의 최종 위치를 맵에서 확인<br/>
                - 이동경로 추적 : 배송 차량의 운행 추적 경로를 맵에서 확인
              </div>
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>         
                                배송정보
              </div>
              <div className="common_txt">
                - 배송차량의 거점(목적지)/경우지 출/도착 시간 정보 자동 제공<br/>
                - 배포된 Mobile App(WINS-BizTalk)으로 배송정보 전송 기능
              </div>
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>      
                                기준정보 관리
              </div>
              <div className="common_txt">
                - GPS번호/차량정보 <br/>
                - 거점(목적지)관리 : 거점 좌표 등록 및 출/도착 반경 설정<br/>
                - 경유지(Fence)관리 : Fence 좌표 등록 및 출/도착 반경 설정<br/>
                - ERP의 기준정보 관리 기능연계
              </div>
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>      
                                설정된 특정지역 접근/이탈시 비상 알람 기능
              </div>
              <div className="common_ttWrap">
                <span className="common_ttIcon02">■</span>      
                                권한 설정
              </div>
              <div className="common_txt">
                - 개인 별/그룹 별/고객사별 권한 설정(메뉴, 제공기능, 데이터 조회)<br/>
              </div>
            </div>
            <br/><br/>
            <img src={require("../../../images/patent_introduce01.jpg").default} className="" />
            <br/><br/><br/>
            <div className="common_imgTT02">
                              차량관제 모니터링[웹뷰 -2]
            </div>
            <br/><br/>
            <img src={require("../../../images/patent_introduce02.jpg").default} className="" />
          </div> */}

          </div>
        </Card>
      </Page>
    </>  
  );
}

export default PLT010601;