import {React,useState,useEffect} from "react";
import { home_url } from '../../../js/globalProperties';
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT010701(props) {
  const [DATA,setDATA] = useState([]);
  let items = new Array();

  const example = [
    {
      type: 'image',
      src: require("../../../images/example.png").default,
      label: '업무사례',
    },
    {
      type: 'image',
      src: require("../../../images/transportation_example.png").default,
      label: '운송사례',
    },
    {
      type: 'image',
      src: require("../../../images/manufacturing_example.png").default,
      label: '제조사례',
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  
    
    const handleClick = (index) => {
      if (index < example.length) {
        setCurrentIndex(index);
      }
    };

  useEffect(async()=>{
    try {
      const requestOptions = { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"OPEN_API_YN":"Y"})
      };
      fetch(home_url+'/api/slpportal/getLogisticsInfo', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
        items = data.DATA;
        setDATA(items[0]);
      });
    } catch(error) {
      console.log(error);
    }
  },[]);

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          {/* <Typography className="listTable_tt"> 
           사례
          </Typography>  */}
            <div className="title_tab_wrap"> 
            {/* <div className="logisticsCouncil_Wrap"> */}
             {/* <div className="image-scroll"> 
             <img src={require("../../../images/case_auto.png").default} className="" width= "1200px"  height = 'auto' />
             <img src={require("../../../images/example.png").default} className="" width= "1200px"  height = 'auto' />
            </div>  */}
            <ul>
            {example.map((item, index) => (
              <li key={index} className={`title_tab_select ${currentIndex === index ? 'active' : ''}`}>
                <a onClick={() => handleClick(index)} style={{ cursor: "pointer" }}>
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {example[currentIndex] && ( // items[currentIndex]가 정의되어 있는지 확인
       
          <img src={example[currentIndex].src} alt={example[currentIndex].label} width="800" height="auto" />
        )
      }
            {/* </div> */}
          {/* </div> */}
        </Card>
      </Page>
    </> 
  );
}

export default PLT010701;