import {React,useState,useEffect} from "react";
import { home_url } from '../../../js/globalProperties';
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT010801(props) {
  const [DATA,setDATA] = useState([]);
  let items = new Array();

  useEffect(async()=>{
    try {
      const requestOptions = { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"OPEN_API_YN":"Y"})
      };
      fetch(home_url+'/api/slpportal/getLogisticsInfo', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
        items = data.DATA;
        setDATA(items[0]);
      });
    } catch(error) {
      console.log(error);
    }
  },[]);

  return (
    <>
       <Page title="Totalboard | Minimal-UI"> 
       <Card> 
          {/* <Typography className="listTable_tt">
          문의
          </Typography> */}
          <div className="title_tab_wrap">
            {/* <div className="logisticsCouncil_Wrap"> */}
              <div className="common_img">
                <img src={require("../../../images/inquiry.png").default} className="" width= "700px"  height = 'auto' />
              {/* </div> */}
            </div>
          </div>
        </Card>
      </Page> 
    </> 
  );
}

export default PLT010801;