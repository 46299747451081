import {React,useState,useEffect} from "react";
import { home_url } from '../../../js/globalProperties';
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

function PLT010501(props) {
  const [DATA,setDATA] = useState([]);
  let items = new Array();

  useEffect(async()=>{
    try {
      const requestOptions = { 
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"OPEN_API_YN":"Y"})
      };
      fetch(home_url+'/api/slpportal/getLogisticsInfo', requestOptions)
        .then(function (response) { return response.json(); })
        .then(function (data) {
        items = data.DATA;
        setDATA(items[0]);
      });
    } catch(error) {
      console.log(error);
    }
  },[]);

  return (
    <>
      <Page title="Totalboard | Minimal-UI">
        <Card>
          {/* <Typography className="listTable_tt">
          물류협의체 소개
          </Typography> */}
          <div className="common_totalWrap02">
            <div className="logisticsCouncil_Wrap">
              <div className="logisticsCouncil_txt01">
                 {DATA.BUSSINESS_YEAR}차년도 <span className="logisticsCouncil_txtblue">{DATA.THIS_YEAR_COMPANY_CNT}개사 협약 완료</span> ({DATA.THIS_YEAR_MONTH} 현재 {DATA.ALL_COMPANY_CNT}개사 협약)  
              </div>
              <div className="logisticsCouncil_txt02">
                · {DATA.LOGISTICS_COMPANY_CNT}개사 가입
              </div>
              <div className="logisticsCouncil_txt03">
                · {DATA.MANUFACTURING_COMPANY_CNT}개사 가입
              </div>
              <div className="logisticsCouncil_txt04">
                · {DATA.OTHER_COMPANY_CNT}개 기관 가입
              </div>
            </div>
          </div>
        </Card>
      </Page>
    </> 
  );
}

export default PLT010501;