import React from "react";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import { useState, useEffect } from "react";
import Page from './Page';
import ReserveEvent from "../PortalMain/Sections/ReserveEvent";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/styles";


function PLT030101(props) {
  
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "800px",
      backgroundColor: "white",
      border: "2px solid #000",
      boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      padding: "32px 32px 16px",
      zIndex: "5000"
    },
  }));
  const classes = useStyles();
  const [RECEIVE_USER_ID, setRECEIVE_USER_ID] = useState();
  // const [Adress,setAdress] = useState(false);
  
  // const AdressClose = () =>{
  //   setAdress(false);
  // }
  const [Reserve,setReserve] = useState(false);
  
  const ReserveOpen = () =>{
    setReserve(true)
  }

  

  return (
    <>
   
      <Page title="Totalboard | Minimal-UI">
        <Card>
          {/* <Typography className="listTable_tt">
          실증센터
          <br></br>
          <span style ={{"font-size": "12px" ,"font-weight": "100" }}>주소 : 경기도 시흥시 군자천로 131번길 77</span>
          </Typography> */}
          {/* <Divider/> */}
          <div className="common_totalWrap">
            <div className="common_ttWrap">
              <span className="common_ttIcon"></span>         
                              시연장 내부 및 자동화 설비 구성
            </div>  
            <div className="common_img">
              <img src={require("../../../images/room_intoduce01.png").default} className="" />
            </div>
            <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            모니터링 존 구성 내용
            </div>  
            <div className="common_img">
              <img src={require("../../../images/room_intoduce02.png").default} className="" />
            </div>
            <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            시연장 내부 인테리어 및 견학 동선
            </div>  
            <div className="common_img">
              <img src={require("../../../images/room_intoduce03.png").default} className="" />
            </div>
            <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            자동화설비 시스템 (입고)  
            </div>  
            <div className="common_img">
              <img src={require("../../../images/room_intoduce04.png").default} className="" />
            </div>
            <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            자동화설비 시스템[멀티셔틀 출고 & AGV 재고 보충 입고] 
            </div>  
            <div className="common_img">
              <img src={require("../../../images/room_intoduce05.png").default} className="" />
            </div>
            <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            자동화설비 시스템 
            </div>  
            <div className="common_img">
              <img src={require("../../../images/room_intoduce06.png").default} className="" />
            </div>
            <div className="common_ttWrap">
             <span className="common_ttIcon"></span>         
                            자동화설비 시스템 장점
            </div>  
            <div className="common_img">
              <img src={require("../../../images/room_intoduce07.png").default} className="" />
            </div>
          </div>
        
          {/* <Button onClick={() => ReserveOpen()} className="ButtonStyle02" variant="contained">예약하기</Button> */}
        </Card>
      </Page>
      {/* <Modal
        className={classes.modal}
        open={Reserve}
        // onClose={()=>AdressClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        
        <Fade in={Reserve}>
          <div  style={{textAlign:"center" ,  width: '55%'}}>
            <ReserveEvent setReserve={setReserve} USER_ID = {localStorage.getItem('USER_ID')}/>
          </div>
        </Fade>
      
      </Modal> */}
       
    </>
  );
}

export default PLT030101;