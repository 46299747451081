import React ,{ useState , useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {
  Button,
  Box,
  Card,
  Typography,
  Divider
} from '@material-ui/core';
import Page from './Page';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow next" onClick={onClick}>
      {/* &gt; */}
      <img src = {require("../../../images/arrowR.png").default} alt="Next" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow prev" onClick={onClick}>
      {/* &lt; */}
      <img src ={require("../../../images/arrowL.png").default} alt="Previous" />
    </div>
  );
};
function PLT000101_01(props) {
  const slidesRef = useRef([]);
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };


  
  

  const slides = [
    { id: 1, src: require("../../../images/banner1.png").default ,  alt: "Image 1"},
    { id: 2, src: require("../../../images/banner2.png").default ,  alt: "Image 2"},
    { id: 3, src: require("../../../images/banner3.png").default ,  alt: "Image 3"}
  ];

  return (
    <div className="slider-container" style={{ width: "1050px" ,height: "670px", position: "relative" }}>
      {/* <h2> Simple Slider </h2> */}
      <Slider {...settings}>
        {slides.map((slide,index) => (
          <div key={slide.id}>
           <img src={slide.src} alt={slide.alt} style={{width: "100%", height: "600px"}} />
           </div>
        ))}
      </Slider>
    </div>
  );
};


//   return (
//     <>
//       <Page title="Totalboard | Minimal-UI">
//         <Card>
//           {/* <Typography className="listTable_tt">
          
//           </Typography> */}
//           <div className="pop_totalWrap">
//             <div className="pop_ttWrap">
//               <span className="pop_ttIcon"></span>         
//                               수요실태조사
//             </div>
//           <Divider/>
//           <img src={require("../../../images/main_graph_banner_02.png").default} />
//           </div>
//         </Card>
//       </Page>
//     </>
//   );
// }

export default PLT000101_01;